var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "joinus" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_4_3")))])
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-4 text-center jsc-card" },
            [
              _c("b-card", { staticClass: "jsc-card-body" }, [
                _c("div", { staticClass: "text-center juc-card-icon" }, [
                  _c("i", { staticClass: "fas fa-gift" })
                ]),
                _c("div", { staticClass: "jsc-icon-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("card.item_1.title")) + " ")
                ]),
                _c("div", {
                  staticClass: "jsc-content",
                  domProps: { innerHTML: _vm._s(_vm.$t("card.item_1.content")) }
                })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4 text-center jsc-card" },
            [
              _c("b-card", { staticClass: "jsc-card-body" }, [
                _c("div", { staticClass: "text-center juc-card-icon" }, [
                  _c("i", { staticClass: "bx bxs-shield" })
                ]),
                _c("div", { staticClass: "jsc-icon-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("card.item_2.title")) + " ")
                ]),
                _c("div", {
                  staticClass: "jsc-content",
                  domProps: { innerHTML: _vm._s(_vm.$t("card.item_2.content")) }
                })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4 text-center jsc-card" },
            [
              _c("b-card", { staticClass: "jsc-card-body" }, [
                _c("div", { staticClass: "text-center juc-card-icon" }, [
                  _c("i", { staticClass: "bx bxs-plane-alt" })
                ]),
                _c("div", { staticClass: "jsc-icon-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("card.item_3.title")) + " ")
                ]),
                _c("div", {
                  staticClass: "jsc-content",
                  domProps: { innerHTML: _vm._s(_vm.$t("card.item_3.content")) }
                })
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-4 text-center jsc-card" },
            [
              _c("b-card", { staticClass: "jsc-card-body" }, [
                _c("div", { staticClass: "text-center juc-card-icon" }, [
                  _c("i", { staticClass: "bx bxs-trophy" })
                ]),
                _c("div", { staticClass: "jsc-icon-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("card.item_4.title")) + " ")
                ]),
                _c("div", {
                  staticClass: "jsc-content",
                  domProps: { innerHTML: _vm._s(_vm.$t("card.item_4.content")) }
                })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4 text-center jsc-card" },
            [
              _c("b-card", { staticClass: "jsc-card-body" }, [
                _c("div", { staticClass: "text-center juc-card-icon" }, [
                  _c("i", { staticClass: "bx bxs-calendar" })
                ]),
                _c("div", { staticClass: "jsc-icon-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("card.item_4.title")) + " ")
                ]),
                _c("div", {
                  staticClass: "jsc-content",
                  domProps: { innerHTML: _vm._s(_vm.$t("card.item_5.content")) }
                })
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4 text-center jsc-card" },
            [
              _c("b-card", { staticClass: "jsc-card-body" }, [
                _c("div", { staticClass: "text-center juc-card-icon" }, [
                  _c("i", { staticClass: "fas fa-hand-holding-heart" })
                ]),
                _c("div", { staticClass: "jsc-icon-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("card.item_6.title")) + " ")
                ]),
                _c("div", {
                  staticClass: "jsc-content",
                  domProps: { innerHTML: _vm._s(_vm.$t("card.item_6.content")) }
                })
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row mb-5" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 text-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "p-3 jsc-work-btn",
                  attrs: { block: "", variant: "primary" }
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.104.com.tw/company/1a2x6bm2xj",
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("bigBtn"))),
                      _c("i", { staticClass: "fas fa-external-link-alt" })
                    ]
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }