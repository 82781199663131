<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'aboutus'" :bannerTitle="bannerTitle" :breadCrumb="breadCrumb" />
        <JoinUsBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>

    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import JoinUsBody from './JoinUsBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'JoinUsMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_4_3"),
            }
        },
        components: {
            Navbar,
            Header,
            JoinUsBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: "JOIN US",
                breadCrumb: [{
                    name: this.$t('navbar.menu_4')
                }],
            }
        },
    }
</script>

<style>
    .bg-color {
        background-color: #eff4f5;
    }
</style>