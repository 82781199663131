<template>
  <div id="joinus" class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_4_3") }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 text-center jsc-card">
          <b-card class="jsc-card-body">
            <div class="text-center juc-card-icon">
              <i class="fas fa-gift"></i>
            </div>
            <div class="jsc-icon-title">
              {{ $t("card.item_1.title") }}
            </div>
            <div class="jsc-content" v-html="$t('card.item_1.content')">
              <!-- 年終獎金<br>
                            節慶獎金<br>
                            生日禮金<br>
                            年終摸彩 -->
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 text-center jsc-card">
          <b-card class="jsc-card-body">
            <div class="text-center juc-card-icon">
              <i class="bx bxs-shield"></i>
            </div>
            <div class="jsc-icon-title">
              {{ $t("card.item_2.title") }}
            </div>
            <div class="jsc-content" v-html="$t('card.item_2.content')">
              <!-- 勞保<br>
                            健保<br>
                            團保<br>
                            雇主責任險 -->
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 text-center jsc-card">
          <b-card class="jsc-card-body">
            <div class="text-center juc-card-icon">
              <i class="bx bxs-plane-alt"></i>
            </div>
            <div class="jsc-icon-title">
              {{ $t("card.item_3.title") }}
            </div>
            <div class="jsc-content" v-html="$t('card.item_3.content')">
              <!-- 國內、外旅遊<br>
                            部門聚餐<br>
                            慶生會<br>
                            尾牙/春酒 -->
            </div>
          </b-card>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 text-center jsc-card">
          <b-card class="jsc-card-body">
            <div class="text-center juc-card-icon">
              <i class="bx bxs-trophy"></i>
            </div>
            <div class="jsc-icon-title">
              {{ $t("card.item_4.title") }}
            </div>
            <div class="jsc-content" v-html="$t('card.item_4.content')">
              <!-- 員工制服<br>
                            誤餐費<br>
                            完整的教育訓練<br>
                            順暢的升遷管道<br> -->
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 text-center jsc-card">
          <b-card class="jsc-card-body">
            <div class="text-center juc-card-icon">
              <i class="bx bxs-calendar"></i>
            </div>
            <div class="jsc-icon-title">
              {{ $t("card.item_4.title") }}
            </div>
            <div class="jsc-content" v-html="$t('card.item_5.content')">
              <!-- 特休 / 年假<br>
                            生理假<br>
                            產檢假/安胎假<br>
                            產假/陪產假<br>
                            育嬰假<br> -->
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 text-center jsc-card">
          <b-card class="jsc-card-body">
            <div class="text-center juc-card-icon">
              <i class="fas fa-hand-holding-heart"></i>
            </div>
            <div class="jsc-icon-title">
              {{ $t("card.item_6.title") }}
            </div>
            <div class="jsc-content" v-html="$t('card.item_6.content')">
              <!-- 結婚禮金<br>
                            生育津貼<br>
                            旅遊補助<br>
                            住院慰問金<br>
                            勞退金提發<br> -->
            </div>
          </b-card>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-12 text-center">
          <b-button class="p-3 jsc-work-btn" block variant="primary"
            ><a href="https://www.104.com.tw/company/1a2x6bm2xj" target="_blank"
              >{{ $t("bigBtn") }}<i class="fas fa-external-link-alt"></i></a
          ></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinUsContent",
  data() {
    return {};
  },
};
</script>

<style>
#joinus .container {
  max-width: 1000px;
}

.juc-card-icon {
  height: 96px;
}

.jsc-card-body {
  height: 360px !important;
  margin: 0px 10px;
}

.jsc-work-btn {
  height: 100px;
  font-size: 28px;
}

.jsc-work-btn a {
  color: #fff;
}

.jsc-card {
  margin-bottom: 30px;
}

.jsc-card .card {
  /* height: 32vh !important; */
  border: 1px solid #33ccff;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fa-external-link-alt:before {
  margin-left: 12px;
}

.juc-title h1 {
  font-size: 50px;
  color: #000033;
  margin-bottom: 30px;
}

.juc-card-icon i {
  margin-top: 30px;
  color: #33ccff;
  font-size: 4rem;
}

.jsc-icon-title {
  font-size: 34px;
  color: #33ccff;
  margin-bottom: 15px;
}

.jsc-content {
  color: #212121;
  font-size: 18px;
}

.btn-primary {
  background-color: #000066;
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  #joinus .container {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  #joinus .container {
    max-width: 600px;
  }

  /*  Medium devices (tablets, 768px and down) */
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .juc-card-icon i {
    margin-top: 5px;
  }

  #joinus .container {
    max-width: 400px;
  }
}
</style>
